/**
 * @copyright 2019 @ DigiNet
 * @author XUANVINH
 * Tab điều kiện lọc chi tiết
 */
import React, {useState} from 'react';
import {Button, Col, FormGroup} from "react-bootstrap";
import ButtonDelete from "../button/button-delete";
import ButtonSearch from "../button/button-search";
import {CheckBox, DateBox, Validator} from "devextreme-react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as action from "../../../actions/advanced-search";
import Config from "../../../config";
import {CustomRule} from "devextreme-react/validator";
import ButtonAdd from "../button/button-add";
import ButtonSave from "../button/button-save";
import ButtonNotSave from "../button/button-notsave";
import DynamicDropdown from '../../common/dropdown/dynamic-dropdown';
import DynamicImportDropdown from '../../common/dropdown/dynamic-import-dropdown';

export class Container extends React.PureComponent {
    //----------------------DECLARE----------------------
    constructor(props) {
        super(props);
        this.state = {
            switchButton: false, // s
            showAddFilter: false,// them điều kiện lọc,
            isPublic: false,
        };
        this.isValid = true;
    }

    //----------------------ENDDECLARE----------------------
    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = {};
        if (!nextProps.switchButton && nextProps.disabledForm && Config.isEmpty(nextProps?.filter)) {
            newState.showAddFilter = false;
        }
        if (prevState.showAddFilter && nextProps.disabledForm && !nextProps.switchButton  && Config.isEmpty(nextProps?.filter)) {
            newState.showAddFilter = false;
        }
        if (Object.keys(newState).length) {
            return newState;
        }
        return null
    }

    //----------------------METHOD----------------------


    loadFilterData = () => {
        const param = {
            FormID: this.props.formID
        };

        this.props.action.getPopOverFilter(param)
    };

    checkIsManager = () => {
        this.props.action.checkIsManager();
    };


    onChangeDynamic = (e, mode) => {
        const {onChangeDynamic} = this.props;
        let param;
        if (mode === 1) {
            param = {
                [e.element.id]: e.value
            }

        } else if (mode === 2) {
            param = {
                [e.id]: e.value
            }
        } else if (mode === 3) {
            param = {
                [e.id]: e.value
            }
        } else {
            param = {
                [e.target.id]: e.target.value
            }
        }
        onChangeDynamic && onChangeDynamic(param);
    };

    onSetDefault = () => {
        const {onSetDefault} = this.props;
        onSetDefault && onSetDefault();
    };

    onCheckItem = (e, data) => {
        if (e.value) {
            this.onAddItem(data);
            return
        }
        this.onDeleteItem(data);
    };

    onAddItem = (data) => {
        const {onAddItem} = this.props;
        onAddItem && onAddItem(data)
    };

    onDeleteItem = (e) => {
        const {onDelete} = this.props;
        onDelete && onDelete(e)
    };

    onInitPopover = (e, data) => {
        const {dataSource} = this.props;
        const isChecked = dataSource && dataSource.find((listValue) => listValue.FieldID === data.FieldID);
        if (isChecked) e.component.option('value', true)
    };

    _onAdd = () => {
        const {onAdd} = this.props;
        this.switchButton();
        onAdd && onAdd();
    };

    onToggleAddFilter = () => {
        this.setState({
            showAddFilter: !this.state.showAddFilter
        })
    };

    switchButton = () => {
        this.setState({
            switchButton: !this.state.switchButton
        })
    };

    //----------------------ENDMETHOD----------------------


    componentDidMount() {
        this.loadFilterData();
        this.checkIsManager();
    }

    render() {
        const {showAddFilter} = this.state;
        const {
            popOverFilter, toggleFilter, switchButton, onValidateDate,
            onNotSave, disabledForm, onSave, dynamicState, onSearch, dataSource, isPublic,
            onChangePublic, isManager, filter
        } = this.props;
        let isManagerF = isManager ? isManager.IsManager : 0;
        return (
            <UI popOverFilter={popOverFilter}
                dataSource={dataSource}
                toggleFilter={toggleFilter}
                dynamicState={dynamicState}
                showAddFilter={showAddFilter}
                switchButton={switchButton}
                isPublic={isPublic}
                isManager={isManagerF}
                disabledForm={disabledForm}
                filter={filter}
                onSearch={onSearch}
                onAddList={this._onAdd}
                onSave={onSave}
                onNotSave={onNotSave}
                onChangePublic={onChangePublic}
                onValidateDate={onValidateDate}
                onInitPopover={this.onInitPopover}
                onCheckItem={this.onCheckItem}
                onSetDefault={this.onSetDefault}
                onDeleteItem={this.onDeleteItem}
                onChangeDynamic={this.onChangeDynamic}
                onToggleAddFilter={this.onToggleAddFilter}
            />
        )
    }
}


const UI = React.memo((props) => {
    //----------------------DECLARE----------------------
    const [state, setState] = useState({
        showFilter: false,
    });

    const {
        dataSource, dynamicState, onChangeDynamic, popOverFilter,
        onCheckItem, onDeleteItem, onInitPopover, onAddList,
        toggleFilter, onSetDefault, switchButton, onSave, onToggleAddFilter,
        showAddFilter, onValidateDate, isPublic, onChangePublic, onNotSave, disabledForm, onSearch,
        isManager, filter
    } = props;

    //----------------------ENDDECLARE----------------------


    //----------------------METHOD----------------------

    const _toggleFilter = () => {
        setState(prevState => ({
            ...prevState,
            showFilter: !state.showFilter
        }));
        if (toggleFilter) toggleFilter();
    };

    const _onSetDefault = () => {
        onSetDefault && onSetDefault()
    };

    const _onAddList = () => {
        onAddList && onAddList();
    };

    const _onSave = () => {
        onSave && onSave();
    };

    const _onToggleAddFilter = () => {
        onToggleAddFilter && onToggleAddFilter();
    };

    const _onNotSave = () => {
        onNotSave && onNotSave();
    };
    //----------------------ENDMETHOD----------------------

    return (
        <React.Fragment>
            <FormGroup id={'detail-search-title'}>
                <Col lg={12}>
                    <div style={{fontSize: '20px'}}> {Config.lang('CRM_Dieu_kien_loc_chi_tiet')}</div>
                </Col>
            </FormGroup>
            <FormGroup>
                <Col lg={12}>
                <span onClick={_toggleFilter} className="mgt5 text-muted cursor-pointer">
                    <i className={`mgl5 fas fa-chevron-${state.showFilter ? 'left' : 'right'}`}/>
                    {Config.lang('CRM_Danh_sach_bo_loc')}
                    </span>
                </Col>
            </FormGroup>
            {
                switchButton ?
                    <React.Fragment>
                        <FormGroup>
                            <Col lg={12}>
                                <CheckBox width={'100%'}
                                          disabled={disabledForm}
                                          value={isPublic}
                                          visible={isManager === 1}
                                          onValueChanged={onChangePublic}
                                          text={Config.lang('CRM_Hien_thi_voi_moi_nguoi')}/>
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col lg={12}>
                                <div style={{display: 'flex'}}>
                                    <ButtonSave disabled={disabledForm}
                                                className={'mgl5'}
                                                onClick={_onSave}
                                                name={Config.lang('CRM_Luu')}/>
                                    <ButtonNotSave disabled={disabledForm}
                                                   name={Config.lang('CRM_Khong_luu')}
                                                   onClick={_onNotSave}/>
                                </div>
                            </Col>
                        </FormGroup>
                    </React.Fragment>
                    :
                    <div>
                        <div style={{display: 'flex'}}>
                            <ButtonAdd className={'mgr5'}
                                       name={Config.lang('CRM_Them_vao_danh_sach')}
                                       onClick={_onAddList}/>
                            <ButtonSearch onClick={onSearch}
                                          name={Config.lang('CRM_Tim_kiem')}/>
                        </div>
                    </div>
            }
            <ListDetailRender dynamicState={dynamicState}
                              disabled={disabledForm}
                              onChangeDynamic={onChangeDynamic}
                              onDeleteItem={onDeleteItem}
                              onValidateDate={onValidateDate}
                              filter={filter}
                              dataSource={dataSource}/>
            <FormGroup style={{marginTop: '15px'}}>
                <Col lg={12}>
                    <Button style={{marginLeft: 0}}
                            onClick={_onToggleAddFilter}
                            className={'mgl5'}
                            bsStyle="primary">
                        {Config.lang('CRM_Them_dieu_kien_loc')}
                    </Button>
                    <Button disabled={disabledForm}
                            onClick={_onSetDefault}
                            className={'mgl5'}
                            bsStyle="primary">
                        {Config.lang('CRM_Dieu_kien_loc_mac_dinh')}
                    </Button>
                </Col>
            </FormGroup>
            <FormGroup>
                <AddFilterList isShow={showAddFilter}
                               dataSource={popOverFilter}
                               onCheckItem={onCheckItem}
                               onInitialized={onInitPopover}
                               value={dataSource}
                />
            </FormGroup>
        </React.Fragment>
    )
},(prevProps, nextProps) => {
    /* Trả về true nếu nextProps bằng prevProps, ngược lại trả về false
    *  Đối với prop truyền trực tiếp mảng object thì sẽ luôn render lại
    * // Không deep compare = JSON.stringnify ảnh hưởng performace - chưa fix
    * */
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
});

/**
 * Đổ danh sách bộ lọc - dữ liệu động
 */
const ListDetailRender = React.memo((props) => {
    //----------------------DECLARE----------------------
    const {dataSource, onChangeDynamic, dynamicState, onDeleteItem, onValidateDate, filter, disabled: disabledProp} = props;

    //----------------------DECLARE----------------------

    //----------------------METHOD----------------------
    const _changeDynamicState = (e, mode) => {
        //Mode 1 = date, 2 = Dropdown dynamic API, 3 Dropdown dynamic Import
        onChangeDynamic && onChangeDynamic(e, mode)
    };


    const _onDeleteItem = (data) => {
        onDeleteItem && onDeleteItem(data);
    };
    
    const disabled = Config.isEmpty(filter?.filterID) && disabledProp;

    //----------------------ENDMETHOD----------------------

    return (
        dataSource && dataSource.map((value, index) => {
            const itemName = Config.language === '84' ? value.FieldName84 : value.FieldName01;
            return (<FormGroup key={`${value.FieldID}-${index}`} style={{marginTop: '15px'}}>
                <Col lg={12}>{itemName}</Col>
                <Col lg={12} style={{display: 'flex'}}>
                    {value.ControlType === 0 &&
                    <React.Fragment>
                        <input id={value.FieldID}
                               disabled={disabled}
                               value={dynamicState[value.FieldID]}
                               onChange={_changeDynamicState}
                               className={'form-control'}/>
                    </React.Fragment>
                    }
                    {value.ControlType === 1 &&
                    <React.Fragment>
                        <DateBox width={"50%"}
                                 id={`${value.FieldID}From`}
                                 disabled={disabled}
                                 value={dynamicState[`${value.FieldID}From`]}
                                 showClearButton={true}
                                 dateSerializationFormat={'yyyy-MM-dd'}
                                 placeholder={Config.lang("CRM_Tu_ngay")}
                                 openOnFieldClick={true}
                                 useMaskBehavior={true}
                                 type={'date'}
                                 pickerType={"calendar"}
                                 showAnalogClock={false}
                                 displayFormat={'dd/MM/y'}
                                 onValueChanged={(e) => onChangeDynamic(e, 1)}
                        >
                            <Validator>
                                <CustomRule
                                    validationCallback={(e) => onValidateDate(e, dynamicState[`${value.FieldID}To`], 1, value.FieldID)}/>
                            </Validator>
                        </DateBox>
                        <div style={{margin: '0 15px'}}/>
                        <DateBox width={"50%"}
                                 id={`${value.FieldID}To`}
                                 disabled={disabled}
                                 value={dynamicState[`${value.FieldID}To`]}
                                 showClearButton={true}
                                 dateSerializationFormat={'yyyy-MM-dd'}
                                 placeholder={Config.lang("CRM_Den_ngay")}
                                 openOnFieldClick={true}
                                 useMaskBehavior={true}
                                 type={'date'}
                                 pickerType={"calendar"}
                                 showAnalogClock={false}
                                 displayFormat={'dd/MM/y'}
                                 onValueChanged={(e) => onChangeDynamic(e, 1)}
                        >
                            <Validator>
                                <CustomRule
                                    validationCallback={(e) => onValidateDate(e, dynamicState[`${value.FieldID}From`], 2, value.FieldID)}/>
                            </Validator>
                        </DateBox>
                    </React.Fragment>
                    }
                    {value.ControlType === 2 && <DynamicDropdown keyID={value.ID}
                                                                 disabled={disabled}
                                                                 id={value.FieldID}
                                                                 value={dynamicState[value.FieldID]}
                                                                 onChange={(e) => onChangeDynamic(e, 2)}
                                                                 apiAddress={value.Address}
                                                                 name={value.Name}/>
                    }
                    {value.ControlType === 3 &&
                    <DynamicImportDropdown value={dynamicState[value.FieldID]}
                                           parentID={value.FieldParentID}
                                           parentValue={dynamicState[value.FieldParentID]}
                                           keyID={value.FieldID}
                                           disabled={disabled}
                                           width={'100%'}
                                           onChange={(e) => onChangeDynamic(e, 3)}
                                           componentID={value.Address}/>
                    }
                    <ButtonDelete disabled={disabled}
                                  className={'mgl5'}
                                  onClick={() => _onDeleteItem(value)}/>
                </Col>
            </FormGroup>)
        }))
});


/**
 * chọn điều kiện lọc
 */
const AddFilterList = React.memo((props) => {
    //----------------------DECLARE----------------------
    const {onCheckItem, dataSource, isShow} = props;
    //----------------------ENDDECLARE----------------------

    //----------------------METHOD----------------------
    const _onCheckItem = (e, data) => {
        onCheckItem && onCheckItem(e, data)
    };

    //----------------------ENDMETHOD----------------------
    if (!isShow) return null;
    return (
        dataSource && dataSource.map((value, index) => {
            const itemName1 = Config.language === '84' ? value.FieldName84 : value.FieldName01;
            return <Col key={`${value.FieldID}-${index}`} lg={4} md={8} sm={12} xs={12} className={'mgb15'}>
                <CheckBox className={'list-check-advanced'}
                          value={props.value.find((pValue) => pValue.FieldID === value.FieldID) ? true : null}
                          onValueChanged={(e) => _onCheckItem(e, value)}
                          width={'100%'}
                          text={itemName1}/>
            </Col>
        }))
});

Container.propTypes = {
    toggleFilter: PropTypes.func,
    onAdd: PropTypes.func,

};
export default connect(
    (state) => ({
        isManager: state.advancedSearch.isManager,
        popOverFilter: state.advancedSearch.popOverFilter,
    }),
    (dispatch) => ({action: bindActionCreators(action, dispatch)}), null, {withRef: true})(Container)